.u-section-1 .u-sheet-1 {
    min-height: 822px;
}

.u-section-1 .u-layout-wrap-1 {
    margin: 111px 0 60px;
}

.u-section-1 .u-layout-cell-1 {
    min-height: 622px;
}

.u-section-1 .u-container-layout-1 {
    padding-left: 10px;
    padding-right: 10px;
}

.u-section-1 .u-text-1 {
    font-size: 2.25rem;
    margin: 0;
}

.u-section-1 .u-text-2 {
    margin: 18px 0 0;
}

.u-section-1 .u-image-1 {
    min-height: 311px;
    background-image: url("https://www.cuptorulculemne.com/public/photos/1/catering/prezentare.gif");
    background-position: 50% 50%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.u-section-1 .u-layout-cell-3 {
    min-height: 311px;
}

.u-section-1 .u-image-2 {
    background-image: url("https://www.cuptorulculemne.com/public/photos/1/catering/IMG_5239.JPG");
    min-height: 311px;
    background-position: 50% 50%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.u-section-1 .u-image-3 {
    min-height: 311px;
    background-image: url("https://www.cuptorulculemne.com/public/photos/1/catering/IMG_5254.JPG");
    background-position: 50% 50%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

@media (max-width: 1199px) {
    .u-section-1 .u-sheet-1 {
        min-height: 713px;
    }
    .u-section-1 .u-layout-wrap-1 {
        margin-right: initial;
        margin-left: initial;
    }
    .u-section-1 .u-layout-cell-1 {
        min-height: 513px;
    }
    .u-section-1 .u-image-1 {
        min-height: 256px;
    }
    .u-section-1 .u-layout-cell-3 {
        min-height: 256px;
    }
    .u-section-1 .u-image-2 {
        min-height: 256px;
    }
    .u-section-1 .u-image-3 {
        min-height: 256px;
    }
}

@media (max-width: 991px) {
    .u-section-1 .u-sheet-1 {
        min-height: 1084px;
    }
    .u-section-1 .u-layout-cell-1 {
        min-height: 244px;
    }
    .u-section-1 .u-image-1 {
        min-height: 392px;
    }
    .u-section-1 .u-layout-cell-3 {
        min-height: 392px;
    }
    .u-section-1 .u-image-2 {
        min-height: 392px;
    }
    .u-section-1 .u-image-3 {
        min-height: 392px;
    }
}

@media (max-width: 767px) {
    .u-section-1 .u-sheet-1 {
        min-height: 2037px;
    }
    .u-section-1 .u-layout-wrap-1 {
        margin-top: 130px;
        margin-right: initial;
        margin-left: initial;
    }
    .u-section-1 .u-layout-cell-1 {
        min-height: 100px;
    }
    .u-section-1 .u-image-1 {
        min-height: 441px;
    }
    .u-section-1 .u-layout-cell-3 {
        min-height: 882px;
    }
    .u-section-1 .u-image-2 {
        min-height: 588px;
    }
    .u-section-1 .u-image-3 {
        min-height: 588px;
    }
}

@media (max-width: 575px) {
    .u-section-1 .u-sheet-1 {
        min-height: 1111px;
    }
    .u-section-1 .u-layout-wrap-1 {
        margin-top: 30px;
        margin-right: initial;
        margin-left: initial;
    }
    .u-section-1 .u-layout-cell-1 {
        min-height: 208px;
    }
    .u-section-1 .u-image-1 {
        min-height: 278px;
    }
    .u-section-1 .u-layout-cell-3 {
        min-height: 555px;
    }
    .u-section-1 .u-image-2 {
        min-height: 370px;
    }
    .u-section-1 .u-image-3 {
        min-height: 370px;
    }
}

.u-section-2 {
    background-image: none;
}

.u-section-2 .u-sheet-1 {
    min-height: 655px;
}

.u-section-2 .u-layout-wrap-1 {
    margin-top: 0;
    margin-bottom: 0;
    background-image: none;
}

.u-section-2 .u-layout-cell-1 {
    min-height: 655px;
}

.u-section-2 .u-container-layout-1 {
    padding: 60px;
}

.u-section-2 .u-text-1 {
    font-size: 2.25rem;
    margin: 119px 0 0;
}

.u-section-2 .u-text-2 {
    margin: 20px 0 0;
}

.u-section-2 .u-image-1 {
    min-height: 655px;
    background-image: url("https://www.cuptorulculemne.com/public/photos/1/catering/patiserie-sarata.gif");
    background-position: 50% 50%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.u-section-2 .u-container-layout-2 {
    padding: 30px 60px;
}

@media (max-width: 1199px) {
    .u-section-2 .u-sheet-1 {
        min-height: 540px;
    }
    .u-section-2 .u-layout-cell-1 {
        min-height: 540px;
    }
    .u-section-2 .u-image-1 {
        min-height: 540px;
    }
}

@media (max-width: 991px) {
    .u-section-2 .u-sheet-1 {
        min-height: 414px;
    }
    .u-section-2 .u-layout-cell-1 {
        min-height: 100px;
    }
    .u-section-2 .u-container-layout-1 {
        padding: 30px;
    }
    .u-section-2 .u-image-1 {
        min-height: 414px;
    }
    .u-section-2 .u-container-layout-2 {
        padding-left: 30px;
        padding-right: 30px;
    }
}

@media (max-width: 767px) {
    .u-section-2 .u-sheet-1 {
        min-height: 721px;
    }
    .u-section-2 .u-container-layout-1 {
        padding: 40px 10px;
    }
    .u-section-2 .u-image-1 {
        min-height: 621px;
    }
    .u-section-2 .u-container-layout-2 {
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media (max-width: 575px) {
    .u-section-2 .u-sheet-1 {
        min-height: 491px;
    }
    .u-section-2 .u-text-1 {
        font-size: 1.875rem;
        margin: 30px 0 0;
    }
    .u-section-2 .u-image-1 {
        min-height: 391px;
    }
}

.u-section-3 .u-sheet-1 {
    min-height: 730px;
}

.u-section-3 .u-layout-wrap-1 {
    margin: 70px 0 60px;
}

.u-section-3 .u-image-1 {
    min-height: 600px;
    background-image: url("https://www.cuptorulculemne.com/public/photos/1/catering/patiserie-dulce.gif");
    background-position: 50% 50%;
        background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.u-section-3 .u-container-layout-1 {
    padding: 30px 60px;
}

.u-section-3 .u-layout-cell-2 {
    min-height: 600px;
}

.u-section-3 .u-container-layout-2 {
    padding: 30px 60px;
}

.u-section-3 .u-text-1 {
    font-size: 2.25rem;
    /* margin: 106px 100px 0; */
}

.u-section-3 .u-text-2 {
    margin: 20px 0 0;
}

@media (max-width: 1199px) {
    .u-section-3 .u-sheet-1 {
        min-height: 625px;
    }
    .u-section-3 .u-layout-wrap-1 {
        margin-right: initial;
        margin-left: initial;
    }
    .u-section-3 .u-image-1 {
        min-height: 495px;
    }
    .u-section-3 .u-layout-cell-2 {
        min-height: 495px;
    }
}

@media (max-width: 991px) {
    .u-section-3 .u-sheet-1 {
        min-height: 509px;
    }
    .u-section-3 .u-image-1 {
        min-height: 379px;
    }
    .u-section-3 .u-container-layout-1 {
        padding-left: 30px;
        padding-right: 30px;
    }
    .u-section-3 .u-layout-cell-2 {
        min-height: 100px;
    }
    .u-section-3 .u-container-layout-2 {
        padding-left: 30px;
        padding-right: 30px;
    }
    .u-section-3 .u-text-1 {
        margin-right: 86px;
        margin-left: 86px;
    }
}

@media (max-width: 767px) {
    .u-section-3 .u-sheet-1 {
        min-height: 799px;
    }
    .u-section-3 .u-image-1 {
        min-height: 569px;
    }
    .u-section-3 .u-container-layout-1 {
        padding-left: 10px;
        padding-right: 10px;
    }
    .u-section-3 .u-container-layout-2 {
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media (max-width: 575px) {
    .u-section-3 .u-sheet-1 {
        min-height: 588px;
    }
    .u-section-3 .u-image-1 {
        min-height: 358px;
    }
    .u-section-3 .u-text-1 {
        font-size: 1.875rem;
        margin-left: 0;
        margin-right: 0;
    }
}

.u-section-4 {
    background-image: none;
}

.u-section-4 .u-sheet-1 {
    min-height: 541px;
}

.u-section-4 .u-layout-wrap-1 {
    margin: 60px auto 60px 0;
}

.u-section-4 .u-layout-cell-1 {
    min-height: 431px;
}

.u-section-4 .u-container-layout-1 {
    padding: 30px 60px;
}

.u-section-4 .u-text-1 {
    font-size: 2.25rem;
    margin: 154px 42px 0;
}

.u-section-4 .u-image-1 {
    min-height: 431px;
    background-image: url("https://www.cuptorulculemne.com/public/photos/1/catering/fructe.gif");
    background-position: 50% 50%;
        background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.u-section-4 .u-container-layout-2 {
    padding: 30px 60px;
}

@media (max-width: 1199px) {
    .u-section-4 .u-sheet-1 {
        min-height: 465px;
    }
    .u-section-4 .u-layout-wrap-1 {
        margin-right: initial;
        margin-left: initial;
    }
    .u-section-4 .u-layout-cell-1 {
        min-height: 355px;
    }
    .u-section-4 .u-image-1 {
        min-height: 355px;
    }
}

@media (max-width: 991px) {
    .u-section-4 .u-sheet-1 {
        min-height: 382px;
    }
    .u-section-4 .u-layout-cell-1 {
        min-height: 100px;
    }
    .u-section-4 .u-container-layout-1 {
        padding-left: 30px;
        padding-right: 30px;
    }
    .u-section-4 .u-image-1 {
        min-height: 272px;
    }
    .u-section-4 .u-container-layout-2 {
        padding-left: 30px;
        padding-right: 30px;
    }
}

@media (max-width: 767px) {
    .u-section-4 .u-sheet-1 {
        min-height: 618px;
    }
    .u-section-4 .u-container-layout-1 {
        padding-left: 10px;
        padding-right: 10px;
    }
    .u-section-4 .u-image-1 {
        min-height: 408px;
    }
    .u-section-4 .u-container-layout-2 {
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media (max-width: 575px) {
    .u-section-4 .u-sheet-1 {
        min-height: 340px;
    }
    .u-section-4 .u-text-1 {
        font-size: 1.875rem;
        margin: 0;
    }
    .u-section-4 .u-image-1 {
        min-height: 257px;
    }
}

.u-section-5 .u-sheet-1 {
    min-height: 730px;
}

.u-section-5 .u-layout-wrap-1 {
    margin: 60px 0;
}

.u-section-5 .u-image-1 {
    min-height: 600px;
    background-image: url("https://www.cuptorulculemne.com/public/photos/1/catering/aperitive-reci.gif");
    background-position: 50% 50%;
        background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.u-section-5 .u-container-layout-1 {
    padding: 30px 60px;
}

.u-section-5 .u-layout-cell-2 {
    min-height: 600px;
}

.u-section-5 .u-container-layout-2 {
    padding: 30px 60px;
}

.u-section-5 .u-text-1 {
    font-size: 2.25rem;
    font-weight: 700;
    /* margin: 157px auto 0; */
}

.u-section-5 .u-text-2 {
    margin: 19px 0 0;
}

@media (max-width: 1199px) {
    .u-section-5 .u-sheet-1 {
        min-height: 625px;
    }
    .u-section-5 .u-layout-wrap-1 {
        margin-right: initial;
        margin-left: initial;
    }
    .u-section-5 .u-image-1 {
        min-height: 495px;
    }
    .u-section-5 .u-layout-cell-2 {
        min-height: 495px;
    }
}

@media (max-width: 991px) {
    .u-section-5 .u-sheet-1 {
        min-height: 509px;
    }
    .u-section-5 .u-image-1 {
        min-height: 379px;
    }
    .u-section-5 .u-container-layout-1 {
        padding-left: 30px;
        padding-right: 30px;
    }
    .u-section-5 .u-layout-cell-2 {
        min-height: 100px;
    }
    .u-section-5 .u-container-layout-2 {
        padding-left: 30px;
        padding-right: 30px;
    }
}


/*aici*/

.u-section-5-bis .u-sheet-1 {
    min-height: 730px;
}

.u-section-5-bis .u-layout-wrap-1 {
    margin: 60px 0;
}

.u-section-5-bis .u-image-1 {
    min-height: 600px;
    background-image: url("https://www.cuptorulculemne.com/public/photos/1/catering/mini-pizza.gif");
    background-position: 50% 50%;
        background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.u-section-5-bis .u-container-layout-1 {
    padding: 30px 60px;
}

.u-section-5-bis .u-layout-cell-2 {
    min-height: 600px;
}

.u-section-5-bis .u-container-layout-2 {
    padding: 30px 60px;
}

.u-section-5-bis .u-text-1 {
    font-size: 2.25rem;
    font-weight: 700;
    /* margin: 157px auto 0; */
}

.u-section-5-bis .u-text-2 {
    margin: 19px 0 0;
}

@media (max-width: 1199px) {
    .u-section-5-bis .u-sheet-1 {
        min-height: 625px;
    }
    .u-section-5-bis .u-layout-wrap-1 {
        margin-right: initial;
        margin-left: initial;
    }
    .u-section-5-bis .u-image-1 {
        min-height: 495px;
    }
    .u-section-5-bis .u-layout-cell-2 {
        min-height: 495px;
    }
}

@media (max-width: 991px) {
    .u-section-5-bis .u-sheet-1 {
        min-height: 509px;
    }
    .u-section-5-bis .u-image-1 {
        min-height: 379px;
    }
    .u-section-5-bis .u-container-layout-1 {
        padding-left: 30px;
        padding-right: 30px;
    }
    .u-section-5-bis .u-layout-cell-2 {
        min-height: 100px;
    }
    .u-section-5-bis .u-container-layout-2 {
        padding-left: 30px;
        padding-right: 30px;
    }
}

@media (max-width: 767px) {
    .u-section-5-bis .u-sheet-1 {
        min-height: 799px;
    }
    .u-section-5-bis .u-image-1 {
        min-height: 569px;
    }
    .u-section-5-bis .u-container-layout-1 {
        padding-left: 10px;
        padding-right: 10px;
    }
    .u-section-5-bis .u-container-layout-2 {
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media (max-width: 575px) {
    .u-section-5-bis .u-sheet-1 {
        min-height: 588px;
    }
    .u-section-5-bis .u-image-1 {
        min-height: 358px;
    }
    .u-section-5-bis .u-text-1 {
        font-size: 1.875rem;
    }
}

.u-section-6 {
    background-image: none;
}

.u-section-6 .u-sheet-1 {
    min-height: 541px;
}

.u-section-6 .u-layout-wrap-1 {
    margin: 60px auto 60px 0;
}

.u-section-6 .u-layout-cell-1 {
    min-height: 431px;
}

.u-section-6 .u-container-layout-1 {
    padding: 30px 60px;
}

.u-section-6 .u-text-1 {
    font-weight: 700;
    font-size: 2.25rem;
    /* margin: 65px auto 0; */
}

.u-section-6 .u-text-2 {
    margin: 34px 0 0;
}

.u-section-6 .u-image-1 {
    min-height: 431px;
    background-image: url("https://www.cuptorulculemne.com/public/photos/1/catering/bufet-cald.gif");
    background-position: 50% 50%;
        background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.u-section-6 .u-container-layout-2 {
    padding: 30px 60px;
}

@media (max-width: 1199px) {
    .u-section-6 .u-sheet-1 {
        min-height: 465px;
    }
    .u-section-6 .u-layout-wrap-1 {
        margin-right: initial;
        margin-left: initial;
    }
    .u-section-6 .u-layout-cell-1 {
        min-height: 355px;
    }
    .u-section-6 .u-image-1 {
        min-height: 355px;
    }
}

@media (max-width: 991px) {
    .u-section-6 .u-sheet-1 {
        min-height: 382px;
    }
    .u-section-6 .u-layout-cell-1 {
        min-height: 100px;
    }
    .u-section-6 .u-container-layout-1 {
        padding-left: 30px;
        padding-right: 30px;
    }
    .u-section-6 .u-image-1 {
        min-height: 272px;
    }
    .u-section-6 .u-container-layout-2 {
        padding-left: 30px;
        padding-right: 30px;
    }
}

@media (max-width: 767px) {
    .u-section-6 .u-sheet-1 {
        min-height: 618px;
    }
    .u-section-6 .u-container-layout-1 {
        padding-left: 10px;
        padding-right: 10px;
    }
    .u-section-6 .u-image-1 {
        min-height: 408px;
    }
    .u-section-6 .u-container-layout-2 {
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media (max-width: 575px) {
    .u-section-6 .u-sheet-1 {
        min-height: 467px;
    }
    .u-section-6 .u-text-1 {
        font-size: 1.875rem;
    }
    .u-section-6 .u-image-1 {
        min-height: 257px;
    }
    .u-section-6 .u-layout-wrap-1 {
        margin: 0;
    }
    .u-section-4 .u-layout-wrap-1 {
        margin: 0;
    }
}

.u-section-7 .u-sheet-1 {
    min-height: 520px;
}

.u-section-7 .u-layout-wrap-1 {
    margin: 60px 0 49px;
}

.u-section-7 .u-image-1 {
    min-height: 400px;
    background-image: url("https://www.cuptorulculemne.com/public/photos/1/catering/desert.gif");
    background-position: 50% 50%;
        background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.u-section-7 .u-container-layout-1 {
    padding: 30px;
}

.u-section-7 .u-layout-cell-2 {
    min-height: 400px;
}

.u-section-7 .u-container-layout-2 {
    padding: 30px;
}

.u-section-7 .u-text-1 {
    margin: none;
}

.u-section-7 .u-text-2 {
    margin: 20px auto 0;
}

@media (max-width: 1199px) {
    .u-section-7 .u-sheet-1 {
        min-height: 380px;
    }
    .u-section-7 .u-layout-wrap-1 {
        margin-bottom: 60px;
        position: relative;
        margin-right: initial;
        margin-left: initial;
    }
    .u-section-7 .u-image-1 {
        min-height: 330px;
    }
    .u-section-7 .u-layout-cell-2 {
        min-height: 330px;
    }
}

@media (max-width: 991px) {
    .u-section-7 .u-sheet-1 {
        min-height: 226px;
    }
    .u-section-7 .u-image-1 {
        min-height: 253px;
    }
    .u-section-7 .u-layout-cell-2 {
        min-height: 100px;
    }
}

@media (max-width: 767px) {
    .u-section-7 .u-sheet-1 {
        min-height: 680px;
    }
    .u-section-7 .u-image-1 {
        min-height: 380px;
    }
    .u-section-7 .u-container-layout-1 {
        padding-left: 10px;
        padding-right: 10px;
    }
    .u-section-7 .u-container-layout-2 {
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media (max-width: 575px) {
    .u-section-7 .u-text-1 {
        margin: none;
    }
    .u-section-7 .u-sheet-1 {
        min-height: 398px;
    }
    .u-section-7 .u-image-1 {
        min-height: 239px;
    }
}

.u-section-8 {
    background-image: none;
}

.u-section-8 .u-sheet-1 {
    min-height: 679px;
}

.u-section-8 .u-layout-wrap-1 {
    margin: 79px auto 79px 0;
}

.u-section-8 .u-image-1 {
    background-image: url("https://www.cuptorulculemne.com/public/photos/1/catering/IMG_5268.JPG");
    min-height: 542px;
    background-position: 50% 50%;
}

.u-section-8 .u-layout-cell-2 {
    min-height: 542px;
}

.u-section-8 .u-text-1 {
    font-size: 3rem;
    margin: 50px 20px 0;
}

.u-section-8 .u-text-2 {
    font-size: 6rem;
    margin: -30px 20px 0 53px;
}

.u-section-8 .u-text-3 {
    margin: 20px 111px 50px 53px;
}

@media (max-width: 1199px) {
    .u-section-8 .u-sheet-1 {
        min-height: 400px;
    }
    .u-section-8 .u-layout-wrap-1 {
        margin-right: initial;
        margin-left: initial;
    }
    .u-section-8 .u-text-1 {
        margin-left: 16px;
        margin-right: 16px;
        font-family: "Playfair Display", serif;
    }
    .u-section-8 .u-text-2 {
        margin-right: 16px;
        margin-left: 44px;
    }
    .u-section-8 .u-text-3 {
        margin-right: 91px;
        margin-left: 44px;
    }
}

@media (max-width: 991px) {
    .u-section-8 .u-text-1 {
        margin-left: 13px;
        margin-right: 13px;
    }
    .u-section-8 .u-text-2 {
        margin-right: 12px;
        margin-left: 33px;
    }
    .u-section-8 .u-text-3 {
        margin-right: 70px;
        margin-left: 33px;
    }
}

@media (max-width: 767px) {
    .u-section-8 .u-text-1 {
        margin-left: 9px;
        margin-right: 9px;
    }
    .u-section-8 .u-text-2 {
        margin-right: 9px;
        margin-left: 25px;
    }
    .u-section-8 .u-text-3 {
        margin-right: 52px;
        margin-left: 25px;
    }
}

@media (max-width: 575px) {
    .u-section-8 .u-text-1 {
        margin-left: 6px;
        margin-right: 6px;
    }
    .u-section-8 .u-text-2 {
        margin-right: 6px;
        margin-left: 16px;
        font-size: 4.5rem;
    }
    .u-section-8 .u-text-3 {
        margin-right: 33px;
        margin-left: 16px;
    }
}

margin-left: 16px;

}

}