input.parsley-success,
select.parsley-success,
textarea.parsley-success {
  color: #468847;
  background-color: #DFF0D8;
  border: 1px dotted #D6E9C6;
}

input.parsley-error,
select.parsley-error,
textarea.parsley-error,
input.parsley-error:focus,
select.parsley-error:focus,
textarea.parsley-error:focus
{
  color: #B94A48;
  background-color: white;
  border: 2px dotted red;
  background-image: url('/public/themes-assets/cuptorul-cu-lemne/images/exl.png');
  background-repeat: no-repeat;
  background-position-x: 97%;
  background-position-y: 50%;
}

.parsley-errors-list {
  margin: 2px 0 3px;
  padding: 0;
  list-style-type: none;
  font-size: 0.9em;
  line-height: 0.9em;
  opacity: 0;

  transition: all .3s ease-in;
  -o-transition: all .3s ease-in;
  -moz-transition: all .3s ease-in;
  -webkit-transition: all .3s ease-in;
}

.parsley-errors-list.filled {
  opacity: 1;
}

.parsley-errors-list li {
  line-height: 24px!important;
  color: red;
}